<template>
  <div>
    <div class="vx-row" v-if="doctorModel.ID == null || doctorModel.ID == undefined">
      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("Email") }}</label>
        <vs-input
          v-model="doctorModel.Email"
          class="w-full"
          name="Email"
          v-validate="'email'"
        />
        <span class="text-danger text-sm" v-show="errors.has('Email')">{{
          errors.first("Email")
        }}</span>
      </div>

      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("Password") }}</label>
        <vs-input
          v-model="doctorModel.Password"
          class="w-full"
          name="Password"
          type="password"
          v-validate="'Password'"
        />
        <span class="text-danger text-sm" v-show="errors.has('Password')">{{
          errors.first("Password")
        }}</span>
      </div>

      <div class="vx-col lg:w-1/4 w-full">
        <vs-checkbox class="mt-5" v-model="doctorModel.IsActive"
          >{{ $t("IsActive") }}
        </vs-checkbox>
      </div>

      <div class="vx-col lg:w-1/4 w-full">
        <vs-checkbox class="mt-5" v-model="doctorModel.IsConfirmed"
          >{{ $t("IsConfirmed") }}
        </vs-checkbox>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("TitleEN") }}</label>
        <vs-input
          v-model="doctorModel.TitleEN"
          class="w-full"
          name="TitleEN"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('TitleEN')">{{
          errors.first("TitleEN")
        }}</span>
      </div>

      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("TitleAR") }}</label>
        <vs-input
          v-model="doctorModel.TitleAR"
          class="w-full"
          name="TitleAR"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('TitleAR')">{{
          errors.first("TitleAR")
        }}</span>
      </div>

      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("NameEn") }} *</label>
        <vs-input v-model="doctorModel.NameEN" class="w-full" v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('FullNameEN')">{{
          errors.first("FullNameEN")
        }}</span>
      </div>

      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("NameAr") }}</label>
        <vs-input
          v-model="doctorModel.NameAR"
          v-validate="'required'"
          class="w-full"
          name="FullNameAR"
        />
        <span class="text-danger text-sm" v-show="errors.has('FullNameAR')">{{
          errors.first("FullNameAR")
        }}</span>
      </div>
    </div>

    <div class="vx-row mt-5">
      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("PhoneNumber") }}</label>
        <vs-input
          v-model="doctorModel.Phone"
          class="w-full"
          name="Phone"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('Phone')">{{
          errors.first("Phone")
        }}</span>
      </div>
      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("OtherPhone") }}</label>
        <vs-input v-model="doctorModel.OtherPhone" class="w-full" name="OtherPhone" />
      </div>
      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("Address") }} *</label>
        <vs-input v-model="doctorModel.Address" class="w-full" name="Telephone" />
      </div>
      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("Gender") }}*</label>
        <v-select
          label="Name"
          name="Gender"
          class="mw-full"
          v-model="doctorModel.GenderID"
          :options="genders"
          :reduce="(ID) => ID.ID"
        />
      </div>
    </div>

    <div class="vx-row mt-5">
      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("Country") }}*</label>
        <v-select
          label="Name"
          name="Country"
          class="mw-full"
          v-model="doctorModel.CountryID"
          :options="countries"
          :reduce="(ID) => ID.ID"
          @input="CountryChange()"
        />
      </div>

      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("City") }}*</label>
        <v-select
          label="Name"
          name="City"
          class="mw-full"
          v-model="doctorModel.CityID"
          :options="cities"
          :reduce="(ID) => ID.ID"
        />
      </div>
      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("Language") }}*</label>
        <!-- {{languages}} -->
        <v-select
          label="Name"
          class="mw-full"
          multiple
          v-model="languages"
          :options="ListOfLanguages"
          :reduce="(ID) => ID.ID"
        />
      </div>
      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("Hospital") }}</label>
        <v-select
          label="Name"
          class="mw-full"
          v-model="doctorModel.HospitalID"
          :options="Hospitals"
          :reduce="(ID) => ID.ID"
        />
      </div>
      <div class="vx-col lg:w-1/2 w-full" style="padding-top: 10px">
        <label class="vs-input--label">{{ $t("specialities") }}*</label>
        <v-select
          label="Name"
          class="mw-full"
          multiple
          v-model="specialities"
          :options="ListOfSpecialities"
          :reduce="(ID) => ID.ID"
        />
      </div>
      <div class="vx-col lg:w-1/2 w-full" style="padding-top: 10px">
        <label for="" class="vs-input--label">Graduation Date</label>
        <datepicker
          placeholder="Date"
          v-model="doctorModel.GraduationDate"
          class="w-full"
          name="GraduationDate"
        ></datepicker>
      </div>
    </div>

    <div class="vx-row mt-5">
      <div class="vx-col lg:w-1/2 w-full">
        <label class="vs-input--label">{{ $t("BioEN") }} *</label>
        <vs-textarea name="BioEN" v-model="doctorModel.BioEN"></vs-textarea>
      </div>
      <div class="vx-col lg:w-1/2 w-full">
        <label class="vs-input--label">{{ $t("BioAR") }}</label>
        <vs-textarea name="BioAR" v-model="doctorModel.BioAR"></vs-textarea>
      </div>
    </div>
    <div class="vx-row mt-5">
      <div class="vx-col lg:w-1/2 w-full">
        <label class="vs-input--label">{{ $t("DescriptionEn") }}</label>
        <vs-textarea
          name="DescriptionEN"
          v-model="doctorModel.DescriptionEN"
        ></vs-textarea>
      </div>
      <div class="vx-col lg:w-1/2 w-full">
        <label class="vs-input--label">{{ $t("DescriptionAr") }}</label>
        <vs-textarea
          name="DescriptionAR"
          v-model="doctorModel.DescriptionAR"
        ></vs-textarea>
      </div>
    </div>
    <div class="vx-row mt-5">
      <div class="vx-col lg:w-1/2 w-full mt-2">

      <div>
        <label  class="vs-input--label">{{ $t(" CV") }}
          <a  v-if="doctorModel.CVPath" :href="baseURL + doctorModel.CVPath" download target="_blank"
                        ><feather-icon
                          icon="DownloadIcon"
                          svgClasses="w-5 h-5 hover:text-danger stroke-current"
                          class="ml-2"
                      /></a></label>

          <vs-upload
            automatic
            :limit="1"
            :action="baseURL + 'api/Common/UploadFile'"
            :headers="UploadRequestHeader"
            @on-success="successUpload"
          />

      </div>

      </div>
      <div class="vx-col lg:w-1/2 w-full mt-2">
        <label class="vs-input--label">{{ $t("Recommendation level") }}</label>
        <star-rating
          v-model="doctorModel.RecommendedLevel"
          v-bind:star-size="30"
          :star-points="[
            23,
            2,
            14,
            17,
            0,
            19,
            10,
            34,
            7,
            50,
            23,
            43,
            38,
            50,
            36,
            34,
            46,
            19,
            31,
            17,
          ]"
          v-bind:show-rating="false"
        ></star-rating>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import { domain, mainSetting } from "@/gloabelConstant.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import moduleGender from "@/store/settings/gender/moduleGender.js";
import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js";
import moduleLanguage from "@/store/settings/language/moduleLanguage.js";
import moduleCity from "@/store/settings/city/moduleCity.js";
import Datepicker from "vuejs-datepicker";
import moduleHospital from "@/store/Hospital/moduleHospital.js";
import StarRating from "vue-star-rating";

export default {
  components: {
    vSelect,
    Datepicker,
    StarRating,
  },
  props: {
    doctorModel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    countries() {
      return this.$store.state.CountryList.countries;
    },
    genders() {
      return this.$store.state.GenderList.genders;
    },
    ListOfSpecialities() {
      return this.$store.state.SpecialtyList.specialities;
    },
    ListOfLanguages() {
      return this.$store.state.LanguageList.languages;
    },
    Hospitals() {
      return this.$store.state.HospitalList.Hospitals;
    },
    specialities: {
      get() {
        return this.$store.state.DoctorList.specialities;
      },
      set(newValue) {
        this.$store.commit("DoctorList/SET_specialities", newValue);
      },
    },
    languages: {
      get() {
        return this.$store.state.DoctorList.languages;
      },
      set(newValue) {
        this.$store.commit("DoctorList/SET_languages", newValue);
      },
    },

    cities() {
      return this.$store.state.CityList.cities;
    },
  },
  data() {
    return {
      isMounted: false,
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "API/Course/UploadFile",
    };
  },
  methods: {
     successUpload(event) {
      this.doctorModel.CVPath = event.target.responseText;
      window.showAddSuccess();
    },
    CountryChange() {
      this.doctorModel.CityID = "";
      this.$store.dispatch(
        "CityList/GetAllCitiesByCountryID",
        this.doctorModel.CountryID
      );
    },
    refresh() {
      this.$store.commit("DoctorList/SET_languages", this.languages);
      this.$store.commit("DoctorList/SET_specialities", this.specialities);
    },
  },
  created() {
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }
    if (!moduleGender.isRegistered) {
      this.$store.registerModule("GenderList", moduleGender);
      moduleGender.isRegistered = true;
    }
    if (!moduleSpecialty.isRegistered) {
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }
    if (!moduleLanguage.isRegistered) {
      this.$store.registerModule("LanguageList", moduleLanguage);
      moduleLanguage.isRegistered = true;
    }
    if (!moduleCity.isRegistered) {
      this.$store.registerModule("CityList", moduleCity);
      moduleCity.isRegistered = true;
    }
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
    if(this.cities.length==0)
       this.$store.dispatch("CityList/GetAllCities");
    if(this.languages.length==0)
       this.$store.dispatch("LanguageList/GetAllLanguages");
    if(this.specialities.length==0)
       this.$store.dispatch("SpecialtyList/GetAllSpecialties");
    if(this.genders.length==0)
       this.$store.dispatch("GenderList/GetAllGenders");
    if(this.countries.length==0)
        this.$store.dispatch("CountryList/GetAllCountries");
    if(this.Hospitals.length==0)
       this.$store.dispatch("HospitalList/GetAllHospitals", {});
  },
};
</script>
<style></style>
